import { formatShortDateWithWeekday } from "@clipboard-health/date-time";
import { BottomSheet, Illustration } from "@clipboard-health/ui-components";
import { Title, type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";
import { type ModalShiftData } from "@src/appV2/redesign/Shift/types";
import { parseISO } from "date-fns";

import { OnCallBottomSheetCloseButton } from "../BottomSheet/OnCallShift/OnCallBottomSheetCloseButton";
import { ShiftCardWrapper } from "../Card/Wrapper";
import { OnCallShiftCardContent } from "../Open/OnCall/CardContent";

interface BookingConfirmationBottomSheetProps {
  modalState: UseModalState;
  shift: ModalShiftData;
}

export function OnCallRegisterConfirmationBottomSheet(props: BookingConfirmationBottomSheetProps) {
  const { modalState, shift } = props;

  const {
    offer,
    attributes: { start, end, qualification, timeSlot },
    workplace,
  } = shift;

  return (
    <BottomSheet modalState={modalState}>
      <DialogContent sx={{ backgroundColor: "unset", position: "relative", py: 8, mt: 8 }}>
        <OnCallBottomSheetCloseButton
          top={0}
          onClick={() => {
            modalState.closeModal();
          }}
        />
        <Stack direction="column" alignItems="center" spacing={9} position="relative">
          <Illustration type="shift-booked" />
          <Title variant="h2" component="h2" sx={{ textAlign: "center", width: "16rem" }}>
            You&apos;re on-call for{" "}
            {formatShortDateWithWeekday(start, {
              timeZone: workplace.attributes.location.timezone,
            })}
          </Title>
          <ShiftCardWrapper sx={{ width: "100%" }}>
            <OnCallShiftCardContent
              timeSlot={timeSlot}
              startDate={parseISO(start)}
              endDate={parseISO(end)}
              qualificationText={qualification}
              workplaceTimezone={workplace.attributes.location.timezone}
              deviceTimezone={new Intl.DateTimeFormat().resolvedOptions().timeZone}
              hourlyPay={offer?.attributes.formattedHourlyPay}
            />
          </ShiftCardWrapper>
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
