import { isDefined } from "@clipboard-health/util-ts";
import { useFacilityNotes } from "@src/appV2/Facilities/api/useFacilityNotes";
import { isMandatoryBreakPolicyNote } from "@src/appV2/Facilities/types";
import { useGetPolicyAcknowledgement } from "@src/appV2/Shifts/MandatoryBreakPolicy/api/useGetPolicyAcknowledgement";
import { NoteAcknowledgementAction } from "@src/appV2/Shifts/MandatoryBreakPolicy/types";
import { minutesToMilliseconds } from "date-fns";

interface UseRequiresBreakPolicyAcknowledgementProps {
  workplaceId?: string;
  enabled?: boolean;
  action?: NoteAcknowledgementAction;
}

export function useRequiresBreakPolicyAcknowledgement(
  props: UseRequiresBreakPolicyAcknowledgementProps
) {
  const { workplaceId, enabled = true, action = NoteAcknowledgementAction.BOOK_SHIFT } = props;

  const isFacilityNotesEnabled = isDefined(workplaceId) && enabled;
  const { data: facilityNotesData, isLoading: isFacilityNotesLoading } = useFacilityNotes(
    workplaceId ?? "",
    { enabled: isFacilityNotesEnabled }
  );

  const { noteId: breakPolicyNoteId } =
    facilityNotesData?.find((note) => isMandatoryBreakPolicyNote(note)) ?? {};

  const isPolicyAcknowledgementEnabled = isDefined(breakPolicyNoteId);
  const { data: policyAcknowledgementData, isLoading: isPolicyAcknowledgementLoading } =
    useGetPolicyAcknowledgement(
      {
        noteId: breakPolicyNoteId ?? "",
        policyAcknowledgementAction: action,
      },
      { enabled: isPolicyAcknowledgementEnabled, staleTime: minutesToMilliseconds(5) }
    );

  const requiresBreakPolicyAcknowledgement =
    policyAcknowledgementData?.data.length === 0 && isDefined(breakPolicyNoteId);

  const isLoading =
    (isFacilityNotesEnabled && isFacilityNotesLoading) ||
    (isPolicyAcknowledgementEnabled && isPolicyAcknowledgementLoading);

  return {
    requiresBreakPolicyAcknowledgement,
    breakPolicyNoteId,
    isLoading,
  };
}
